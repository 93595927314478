<template>
  <div>
    <div class="row" v-if="classRoom">
      <div class="col-lg-12" >
        <div class="widjet">
          <div class="widjethdr" style="text-align: right;">
            <div style="width: auto"></div>
            <div>
              <div class="flexitm" v-if="timeTableRecord.tables.length == 0 && userDetail.profile.name != 'Teacher'">
                <button  v-if="previewTableList.length == 0"
                  @click.prevent="previewTimeTable"
                  :disabled="
                    !newTimeTable.days ||
                    !newTimeTable.time ||
                    !newTimeTable.periods ||
                    !newTimeTable.startTime
                  "
                  style="height: 39px;width: 112px;"
                  type="button"
                  class="btn btnsml"
                >
                  <span>Preview</span>
                </button>
                <button
                v-if="previewTableList.length > 0"
                  @click.prevent="generateTimeTable"
                  :disabled="
                    !newTimeTable.days ||
                    !newTimeTable.time ||
                    !newTimeTable.periods ||
                    !newTimeTable.startTime
                  "
                  style="height: 39px;width: 112px;"
                  type="button"
                  class="btn btnsml"
                >
                  <span>Generate</span>
                </button>
              </div>
              <div class="flexitm" v-if="timeTableRecord.tables.length > 0 && !isEdit && userDetail.profile.name != 'Teacher'">
                <!-- <button
                  type="button"
                  class="btn btnsml"
                  @click.prevent="proceedToEdit()"
                >
                  <span>Edit</span>
                </button> -->
                <button
                @click.prevent="proceedToEdit()"
                         
                          class="btn round orange tooltipt"
                        >
                          <i class="fas fa-edit" style="margin-left: 3px;"></i
                          ><span data-title="Edit" class="tooltiptext">Edit</span>
                        </button>
                <!-- <button style="margin-left: 6px;"
                  type="button"
                  class="btn btnsml"
                  @click="showDeleteModal(timeTableRecord._id)"
                 
                >
                  <span>Delete</span>
                </button> -->
                <button  class="btn round red tooltipt" @click="showDeleteModal(timeTableRecord._id)" >
                          <i class="far fa-trash-alt"></i
                          ><span class="tooltiptext">Delete</span>
                        </button>
              </div>
              <div class="flexitm" v-if="timeTableRecord.tables.length > 0 && isEdit && userDetail.profile.name != 'Teacher'">
               
                <button
                  type="button"
                  class="btn btnsml"
                  @click.prevent="saveTimeTable()"
                >
                  <span>Publish</span>
                </button>

                <button
                @click.prevent="cancelEdit()"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
              </div>
              <!-- <div class="flexitm" v-if="timeTableRecord.tables.length > 0 && isEdit && userDetail.profile.name != 'Teacher'">
                <button
                  type="button"
                  class="btn btnsml"
                  @click.prevent="saveTimeTable()"
                >
                  <span>Publish</span>
                </button>
              </div> -->
            </div>
          </div>

          <div class="widjetcontent" v-if="timeTableRecord.tables.length > 0">
            <div class="restable">
              <div class="resrow resheader">
                <div class="cell" style="text-align: center;" ><span style="position: absolute;
    top: 20px;
    left: 80px;">Day</span></div>
                <div class="cell" style="text-align: center;" :key="cIndex" v-for="(tc, cIndex) in timeTableRecord.tables">
                  <div v-if="isEdit" style="text-align: center;">
                    <input v-if="cIndex == 0"
                      type="time"
                      @change="validateSavedTime('from',tc,cIndex)"
                      v-model="tc.from"
                      :name="'StartTime' + cIndex"
                      placeholder="Select start time"
                      class="form-control form-input"
                    />
                    <input v-else
                      type="time"
                      @change="validateSavedTime('from',tc,cIndex,timeTableRecord.tables[cIndex - 1].to,tc.from)"
                      v-model="tc.from"
                      :disabled="!timeTableRecord.tables[cIndex - 1].to"
                      :name="'StartTime' + cIndex"
                      placeholder="Select start time"
                      class="form-control form-input"
                    />
                    <!-- <span v-if="cIndex != 0">{{ timeTableRecord.tables[cIndex - 1].to }}</span> -->

                    --

                    <input 
                      type="time"
                      v-model="tc.to"
                      :min="missn"
                      :disabled="!tc.from"
                      @change="validateSavedTime('to',tc,cIndex,tc.from,tc.to)"
                      :name="'EndTime' + cIndex"
                      placeholder="Select end time"
                      class="form-control form-input"
                    />
                  </div>
                  <span v-else>
                    <span>{{ cIndex + 1 }}</span>
                    <br /> {{ tc.from }} - {{ tc.to }}
                  </span>
                </div>

                <div class="cell" v-if="isEdit">
                  <b-icon icon="plus-circle" variant="primary" style="height: 25px;margin-right: 5px;" @click.prevent="addNewTimePeriod()"></b-icon>
                  <b-icon icon="dash-circle" variant="danger" style="height: 25px" @click.prevent="removeTimePeriod()"></b-icon>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Monday
                </div>
                <div class="cell" style="text-align: center;" data-title="Monday" :key="mIndex" v-for="(m, mIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit" style="text-align: center;">{{ m['mon'] ? subjectGroup[ m['mon'] ] : '' }}</span>
                  <select
                    v-model="m['mon']"
                    :name="'Monday' + mIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Tuesday
                </div>
                <div class="cell" style="text-align: center;" data-title="Tuesday" :key="tIndex" v-for="(t, tIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ t['tue'] ? subjectGroup[ t['tue'] ] : '' }}</span>
                  <select
                    v-model="t['tue']"
                    :name="'Tuesday' + tIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Wednesday
                </div>
                <div class="cell" style="text-align: center;" data-title="Wednesday" :key="wIndex" v-for="(w, wIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ w['wed'] ? subjectGroup[ w['wed'] ] : ''}}</span>
                  <select
                    v-model="w['wed']"
                    :name="'Wednesday' + wIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Thursday
                </div>
                <div class="cell" style="text-align: center;" data-title="Thursday" :key="thIndex" v-for="(th, thIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ th['thur'] ? subjectGroup[ th['thur'] ] : '' }}</span>
                  <select
                    v-model="th['thur']"
                    :name="'Thursday' + thIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Friday
                </div>
                <div class="cell" style="text-align: center;" data-title="Friday" :key="friIndex" v-for="(f, friIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ f['fri'] ? subjectGroup[ f['fri'] ] : ''}}</span>
                  <select
                    v-model="f['fri']"
                    :name="'Friday' + friIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow" v-if="timeTableRecord.days == 'Mon-Sat' || timeTableRecord.days == 'Mon-Sun'">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Saturday
                </div>
                <div class="cell" style="text-align: center;" data-title="Saturday" :key="satIndex" v-for="(s, satIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ s['sat'] ? subjectGroup[ s['sat'] ] : ''}}</span>
                  <select
                    v-model="s['sat']"
                    :name="'Saturday' + satIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="resrow" v-if="timeTableRecord.days == 'Mon-Sun'">
                <div class="cell" data-title="Day" style="text-align: center;" >
                  Sunday
                </div>
                <div class="cell" style="text-align: center;" data-title="Sunday" :key="sunIndex" v-for="(su, sunIndex) in timeTableRecord.tables">
                  <span v-if="!isEdit">{{ su['sun'] ? subjectGroup[ su['sun'] ] : ''}}</span>
                  <select
                    v-model="su['sun']"
                    :name="'Sunday' + sunIndex"
                    class="form-control form-input"
                    v-else
                  >
                    <!-- <option :value="null">--Select--</option> -->
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div style="display:flex;width:100%;justify-content: center;" v-if="isEdit">
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Sat' || timeTableRecord.days == 'Mon-Sun'">
                <a class="link-color" @click.prevent="removeAddedDay('sat')">Remove Saturday</a>
              </div>
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Sun'">
                <a class="link-color" @click.prevent="removeAddedDay('sun')">Remove Sunday</a>
              </div>
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Fri'">
                <a class="link-color" style="margin-right:25px;" @click.prevent="addNewDay('sat')">Add Saturday</a>
                <a class="link-color" @click.prevent="addNewDay('sun')">Add Sunday</a>
              </div>
            </div>
          </div>
          <div class="form-row p-3" v-if="timeTableRecord.tables.length == 0">
            <div class="form-group input-group col-md-6 mt-3" v-if="userDetail.profile.name != 'Teacher'">
              <span class="has-float-label">
                <label for="clsnameid"
                  >No of Periods <span class="required">*</span></label
                >
                <select
                  @change="clearvalues"
                  v-model="newTimeTable.periods"
                  name="NoOfPeriod"
                  class="form-control form-input"
                  id="deptlistid"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in noOfPeriods"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </span>
            </div>
            <div class="form-group input-group col-md-6 mt-3" v-if="userDetail.profile.name != 'Teacher'">
              <span class="has-float-label">
                <label for="clsnameid"
                  >Select Period Duration<span class="required">*</span></label
                >
                <select
                 @change="clearvalues"
                  v-model="newTimeTable.time"
                  name="PeriodInterval"
                  class="form-control form-input"
                  id="deptlistid"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in periodsTime"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </span>
            </div>
            <div class="form-group input-group col-md-6 mt-3" v-if="userDetail.profile.name != 'Teacher'">
              <span class="has-float-label">
                <label for="clsnameid"
                  >How many days per week
                  <span class="required">*</span></label
                >
                <select
                @change="clearvalues"
                  v-model="newTimeTable.days"
                  name="WeekDays"
                  class="form-control form-input"
                  id="deptlistid"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in days"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </span>
            </div>
            <div class="form-group input-group col-md-6 mt-3" v-if="userDetail.profile.name != 'Teacher'">
              <span class="has-float-label">
                <label for="clsnameid"
                  >Select Start Time
                  <span class="required">*</span></label
                >
                <input
                @change="clearvalues"
                  type="time"
                  v-model="newTimeTable.startTime"
                  name="StartTime"
                  placeholder="Select start time"
                  class="form-control form-input"
                />
              </span>
            </div>
            <div class="form-group input-group col-md-12 mt-3" v-if="userDetail.profile.name == 'Teacher'">
              <span class="has-float-label" style="text-align: center;" >
                Only admin can create time table
              </span>
            </div>
          </div>
          <div class="widjetcontent" v-if="previewTableList.length > 0">
            <div class="restable">
              <div class="resrow resheader">
                <div class="cell">Time Periods</div>
                <div class="cell" :key="cIndex" v-for="(tc, cIndex) in previewTableList">
                  <div>
                    <input
                      type="time"
                      v-model="tc.from"
                      @change="validateTIme('from',tc,cIndex)"
                      :name="'StartTime' + cIndex"
                      placeholder="Select start time"
                      class="form-control form-input"
                    />

                    --

                    <input
                      type="time"
                      v-model="tc.to"
                      @change="validateTIme('to',tc,cIndex)"
                      :name="'EndTime' + cIndex"
                      placeholder="Select end time"
                      class="form-control form-input"
                    />
                  </div>
                  <!-- <span v-else>
                    {{ tc.from }} - {{ tc.to }}<br />{{ cIndex + 1 }}
                  </span> -->
                </div>

                <!-- <div class="cell" v-if="isEdit">
                  <b-icon icon="plus-circle" variant="primary" style="height: 25px" @click.prevent="addNewTimePeriod()"></b-icon>
                  <b-icon icon="dash-circle" variant="danger" style="height: 25px" @click.prevent="removeTimePeriod()"></b-icon>
                </div> -->
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Subject
                </div>
                <div class="cell" data-title="Monday" :key="mIndex" v-for="(m, mIndex) in previewTableList">
                  <!-- <span v-if="!isEdit">{{ m['mon'] ? subjectGroup[ m['mon'] ] : '' }}</span> -->
                  <select
                    v-model="m['subject']"
                    :name="'Monday' + mIndex"
                    class="form-control form-input"
                    
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in subjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <!-- <div style="display:flex;width:100%;justify-content: center;" v-if="isEdit">
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Sat' || timeTableRecord.days == 'Mon-Sun'">
                <a class="link-color" @click.prevent="removeAddedDay('sat')">Remove Saturday</a>
              </div>
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Sun'">
                <a class="link-color" @click.prevent="removeAddedDay('sun')">Remove Sunday</a>
              </div>
              <div class="cell" v-if="timeTableRecord.days == 'Mon-Fri'">
                <a class="link-color" style="margin-right:25px;" @click.prevent="addNewDay('sat')">Add Saturday</a>
                <a class="link-color" @click.prevent="addNewDay('sun')">Add Sunday</a>
              </div>
            </div> -->
          </div>

        
        </div>
      </div>
      
      </div>
      <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;">Please Select Classroom...</span>
          </div>
        </div>
      <b-modal id="deletePopup" >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
   

    <!-- <vi-spinner
      v-if="loader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    /> -->
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  components: {
    'vi-spinner' : () => import('../../Common/ViSpinner')
  },
  data() {
    return {
      userDetail: {},
      loader: false,
      isEdit: false,
      showSetupTimeTable: false,
      subjects: [],
      previewTableList: [],
      beforeEditTables: [],
      missn: '09:00',
      noOfPeriods: [1,2,3,4,5,6,7,8,9,10],
      periodList: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      previousDay: "",
      deleteId: null,
      newTimeTable: {
        time: null,
        days: null,
        periods: null,
        startTime: '09:00'
      },
      timeTableRecord: {
        days: "",
        class: "",
        classroom: "",
        tables: []
      },
      days: [
        {
          label: "Monday - Friday",
          value: "Mon-Fri",
        },
        {
          label: "Monday - Saturday",
          value: "Mon-Sat",
        },
        {
          label: "Monday - Sunday",
          value: "Mon-Sun"
        }
      ],
      otherSubjects: [
        { _id: "Free Period", subjectName: "Free Period" },
        { _id: "Lunch", subjectName: "Lunch" },
        { _id: "Break", subjectName: "Break" }
      ],
      periodsTime: [
      {
          label: "15 Mins",
          value: 15,
        },
        {
          label: "20 Mins",
          value: 20,
        },
        {
          label: "25 Mins",
          value: 25,
        },
        {
          label: "30 Mins",
          value: 30,
        },
        {
          label: "35 Mins",
          value: 35,
        },
        {
          label: "40 Mins",
          value: 40,
        },
        {
          label: "45 Mins",
          value: 45,
        },
        {
          label: "50 Mins",
          value: 50,
        },
        {
          label: "55 Mins",
          value: 55,
        },
        {
          label: "1 Hours",
          value: 60
        }
        // {
        //   label: "1.05 Hours",
        //   value: 65
        // },
        // {
        //   label: "1.10 Hours",
        //   value: 70
        // },
        // {
        //   label: "1.15 Hours",
        //   value: 75
        // },
        // {
        //   label: "1.20 Hours",
        //   value: 80
        // },
        // {
        //   label: "1.25 Hours",
        //   value: 85
        // },
        // {
        //   label: "1.30 Hours",
        //   value: 90
        // },
        // {
        //   label: "1.35 Hours",
        //   value: 95
        // },
        // {
        //   label: "1.40 Hours",
        //   value: 100
        // },
        // {
        //   label: "1.45 Hours",
        //   value: 105
        // },
        // {
        //   label: "1.50 Hours",
        //   value: 110
        // },
        // {
        //   label: "1.55 Hours",
        //   value: 115
        // },
        // {
        //   label: "2 Hours",
        //   value: 200
        // }
      ],
    };
  },
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    }
  },
  watch: {
    classId: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";
      if (hasCurrentParam != hasOldParam) {
        this.getTimeTableInfo();
      }
    },
    classRoom: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";
      if (hasCurrentParam != hasOldParam) {
        this.getTimeTableInfo();
      }
    },
  },
  created() {
    this.initComponent()
    this.getTimeTableInfo();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    subjectGroup() {
      return this.subjects.reduce((acc, a) => {
        acc[a._id] = a.subjectName;
        return acc;
      }, {});
    }
  },
  methods: {
    validateTIme(type,data,index){

      this.previewTableList.forEach((x, i) => {
        if (i > index) {
         x.from = null
         x.to = null

        } 
        if(i == index){
          if(type == 'from'){
            x.to = null
          }
        }
      });
     

    },
    validateSavedTime(type,data,index,inTime,outTime){

    this.timeTableRecord.tables.forEach((x, i) => {
      if (i > index) {
      x.from = null
      x.to = null

      } 
      if(i == index){
        if(type == 'from'){
          x.to = null
        }
      }
    });

      var parsedInputTime1 = new Date("1970-01-01T" + inTime + ":00");
      var parsedInputTime2 = new Date("1970-01-01T" + outTime + ":00");

      // Compare the two input times
      if (parsedInputTime1 < parsedInputTime2) {
        console.log("The first time is earlier than the second time")

      } else if(parsedInputTime1 >= parsedInputTime2) {
        this.timeTableRecord.tables.forEach((x, i) => {
          if(i == index){
        if(type == 'from'){
          x.from = null
        }
        if(type == 'to'){
          x.to = null
        }
      }
        });
        this.$toasted.error("Please enter a time greater than the last period's end time.");

       
      console.log("Please enter a time greater than the last period's end time.")

      } 
    // this.checkClockOutTime(from,to)

    },
   
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;
        
       // this.selectedBranch = localStorage.getItem("currentBranch");
      }
    },
    async getTimeTableInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          class: this.classId,
          room: this.classRoom,
        };

        const response = await ViService.viXPost(
          "/academic/time_table",
          requestBody,
          userData.token
        );

        if (response.isSuccess && response.data) {
          const resultData = secureUI.secureGet(response.data);
          
          if( resultData.timeTable && resultData.timeTable._id ) {
            this.timeTableRecord = resultData.timeTable;
          }

          this.subjects = [ ...resultData.subjects, ...this.otherSubjects ];
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    clearvalues(){
      this.previewTableList = []
    },
    generateTimeTable() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       
        let checkRequiredFields = this.previewTableList.filter( x => x.subject == null)

        let checkTimeFields = this.previewTableList.filter( x => x.from == null || x.to == null)
      if(checkTimeFields.length == 0){
        if(checkRequiredFields.length == 0){
        for(let z of this.previewTableList){
          z.mon = z.subject
          z.tue = z.subject
          z.wed = z.subject
          z.thur = z.subject
          z.fri = z.subject

          if( this.newTimeTable.days == 'Mon-Sat' ) {
            z.sat = z.subject
          } else if( this.newTimeTable.days == 'Mon-Sun' ) {
            z.sun = z.subject
            
          }

        }

        
        this.loader = true;

        const body = {
          class: this.classId,
          classroom: this.classRoom,
          days: this.newTimeTable.days,
          tables: this.previewTableList
        };
     
        ViService.viXPost(
          '/academic/time_table/save',
          body,
          userData.token
        ).then((res) => {
          if( res.isSuccess ) {
            this.subjects = [ ...res.data.subjects, ...this.otherSubjects ];

            if( res.data.timeTable && res.data.timeTable._id ) {
              this.timeTableRecord = res.data.timeTable;
            }

            this.isEdit = true;
            this.previewTableList = []
            this.$toasted.success(res.message);
          } else {
            this.$toasted.error(res.message);
          }

          this.loader = false;
        }).catch((e) => {
          this.$toasted.error(e);
          this.loader = false;
        });
      }else{
        this.$toasted.error("Please select subjects for all the periods below");
      }
      }else{
        this.$toasted.error("Please fill Time for all Periods");
      }
      }
    },
    previewTimeTable() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {


        let DAYS = [];
        this.previewTableList = []

        let INTERVAL = this.newTimeTable.time;
        let START_TIME = new Date(`2023-01-01T${this.newTimeTable.startTime}`);


        for( let x = 0; x < this.newTimeTable.periods; x++ ) {
          
          let START_FROM = this.previewTableList.length == 0 ? this.newTimeTable.startTime : this.previewTableList[ x - 1 ]['to'];

          START_TIME.setMinutes( START_TIME.getMinutes() + INTERVAL );
         
          let dateFormatStartDate = new Date(START_TIME)

          let twoDigitsHrMin = this.padTo2Digits(dateFormatStartDate.getHours()) + ':' + this.padTo2Digits(dateFormatStartDate.getMinutes());


          let TABLE_OBJ = {
            from: START_FROM,
            toSession: START_TIME.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            fromSession: '',
            to: twoDigitsHrMin,
            isFreeTime: false,
            freeTimeType: "",
            subject: null,
            mon: null,
            tue: null,
            wed: null,
            thur: null,
            fri: null
          };

          DAYS = ["mon", "tue", "wed", "thur", "fri"];

          if( this.newTimeTable.days == 'Mon-Sat' ) {
            TABLE_OBJ['sat'] = null;

            DAYS.push("sat");
          } else if( this.newTimeTable.days == 'Mon-Sun' ) {
            TABLE_OBJ['sun'] = null;
            DAYS.push("sun");
          }

          this.previewTableList.push( TABLE_OBJ );
        }
      
      }
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deletePopup");
    },
    closeDeletePopUp(){
      this.$bvModal.hide("deletePopup");
      this.deleteId = null;
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/academic/time_table/delete/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
         
        
          this.$toasted.success(response.message);
          this.timeTableRecord = {
            days: "",
            class: "",
            classroom: "",
            tables: []
          },
          this.newTimeTable = {
            time: null,
            days: null,
            periods: null,
            startTime: null
          },
         // this.isEdit = false;
          this.closeDeletePopUp();
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    
   padTo2Digits(num) {
      return String(num).padStart(2, '0');
    },
    saveTimeTable() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.loader = true;
     
        const checkEmptyValue = this.timeTableRecord.tables.filter( x => x.from == '' || x.to == '' || x.from == null || x.to == null).length
       if(checkEmptyValue == 0){
        
        for(let x of this.timeTableRecord.tables){
          x.subject = x.mon
        }

        ViService.viXPost(
          '/academic/time_table/save',
          this.timeTableRecord,
          userData.token
        ).then((res) => {
          if( res.isSuccess ) {
            this.timeTableRecord = res.data;
            this.isEdit = false;

            this.$toasted.success(res.message);
          } else {
            this.$toasted.error(res.message);
          }

          this.loader = false;
        }).catch((e) => {
          this.$toasted.error(e);
          this.loader = false;
        });
      }else{
        this.$toasted.error("Please fill all period time");
      }


      }
    },

    proceedToEdit() {
      this.isEdit = true;
      this.previousDay = this.timeTableRecord.days || "";
      this.beforeEditTables = JSON.parse( JSON.stringify( this.timeTableRecord.tables) );
    },
    cancelEdit() {
      this.isEdit = false;
      this.timeTableRecord.days = this.previousDay || "";
      if(this.beforeEditTables.length > 0){
        this.timeTableRecord.tables = [ ...this.beforeEditTables ];
        
      }else{
        this.getTimeTableInfo();
      }
    
    },
    addNewTimePeriod() {
      if(this.timeTableRecord.tables.length < 10){
      
      const timeObj = {
        from: this.timeTableRecord.tables[ this.timeTableRecord.tables.length - 1 ]['to'],
        to: "",
        mon: null,
        tue: null,
        wed: null,
        thur: null,
        fri: null
      };

      if( this.timeTableRecord.days == 'Mon-Sat' ) {
        timeObj['sat'] = null;
      } else if( this.timeTableRecord.days == 'Mon-Sun' ) {
        timeObj['sat'] = null;
        timeObj['sun'] = null;
      }
      this.timeTableRecord.tables.push({ ...timeObj }); 
    }else{
      this.$toasted.error('Maximum 10 Periods...');
    }
    },
    removeTimePeriod() {
      const index = this.timeTableRecord.tables.length - 1;

      this.timeTableRecord.tables.splice( index, 1 );
    },
    addNewDay(d) {
      if( d == 'sat' ) {
        this.timeTableRecord.days = "Mon-Sat";
      } else if( d == 'sun' ) {
        this.timeTableRecord.days = "Mon-Sun";
      }

      for( const x of this.timeTableRecord.tables ) {
        if( d == 'sun' ) {
          x['sat'] = null;
        }
        x[d] = null;
      }
    },
    removeAddedDay(d) {
      const newTimeTables = [];

      for( const x of this.timeTableRecord.tables ) {
        const TABLE_OBJ = {
          from: x.from,
          to: x.to,
          isFreeTime: x.isFreeTime,
          freeTimeType: x.freeTimeType,
          mon: x.mon,
          tue: x.tue,
          wed: x.wed,
          thur: x.thur,
          fri: x.fri
        };

        if( d == 'sun' ) {
          TABLE_OBJ['sat'] = x.sat;
        }

        newTimeTables.push( TABLE_OBJ );
      }

      this.timeTableRecord.days = d == 'sun' ? "Mon-Sat" : "Mon-Fri";
      this.timeTableRecord.tables = [...newTimeTables];
    }
  },
};
</script>

<style scoped>
.link-color {
  color:dodgerblue;
}
</style>
